.careers-form {
  width: 100vw;
  height: 209vw;
  color: #eee4cc;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #522b24;
  box-shadow: 0 0 200px rgba(0, 0, 0, 0.709) inset;
}

.careers-label {
  font-size: 4vw;
}

.careers-input {
  width: 30vw;
  height: 4vw;
  margin: 1.2vw 0;
  font-size: 3vw;
}

.careers-form-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  height: 90vw;
  margin-top: 30vw;
}

.careers-applicant {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 5vw;
}

.careers-positions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 5vw;
}

.careers-resume {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 5vw;
}

.careers-resume-input::-webkit-file-upload-button {
  visibility: hidden;
}

.careers-resume-input::before {
  content: " 📄";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 4vw;
  -webkit-user-select: none;
  cursor: pointer;
  font-size: 3vw;
}

.careers-resume-temp {
  font-size: 5vw;
  width: 30vw;
  text-align: center;
  margin-top: 1.5vw;
  margin-bottom: 0vw;
}

.careers-resume-temp-statement {
  font-size: 3vw;
  width: 30vw;
  text-align: center;
  margin: 1.5vw 0;
}

.careers-phone {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 5vw;
}

.careers-email {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 5vw;
}

.careers-referral {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 3vw;
}

.careers-apply-button {
  border: #e2746abc solid 2px;
  margin-bottom: 3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
  font-size: 4vw;
  color: #365179;
  background-color: #eee4cc;
  border-radius: 2vw;
  width: 15vw;
}

.careers-upload {
  width: 100vw;
  height: 260vw;
  color: #eee4cc;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #522b24;
  box-shadow: 0 0 200px rgba(0, 0, 0, 0.709) inset;
}

.careers-upload-note {
  margin-top: 30vw;
  width: 80vw;
  font-size: 5vw;
  text-align: center;
}
