.app {
  text-align: center;
  color: #eee4cc;
  margin-top: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app-hero {
  width: 90vw;
  margin-top: 4.05vw;
  border-radius: 7vw;
  border: rgba(0, 0, 0, 0.311) solid 0.3vw;
}

.app-info-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 4vw;
  width: 80vw;
  height: 147vw;
}

.app-info-top {
  width: 80vw;
  font-size: 4vw;
  font-weight: 500;
}

.app-info {
  font-size: 4vw;
  font-weight: 400;
  text-align: start;
  line-height: 6vw;
}

.app-info-bottom {
  width: 80vw;
  font-size: 2.8vw;
  font-weight: 500;
}

.app-info-signed-off {
  color: #e2746abc;
  font-size: 2.2vw;
}

.app-beacon {
  width: 10vw;
  filter: brightness(1.2) hue-rotate(120deg);
}

.app-abea-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5vw 0;
}

.app-abea-p {
  font-size: 2.2vw;
  margin: 0;
}

.app-abea {
  width: 20vw;
  margin-bottom: 2vw;
}
