.contact-main {
  text-align: center;
  color: #eee4cc;
  margin-top: 2vw;
  height: 207vw;
}

.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-title {
  width: 80vw;
  font-size: 5vw;
  font-weight: 600;
  margin-top: 10vw;
}

.contact-info {
  text-align: start;
  font-size: 4vw;
  font-weight: 500;
  line-height: 5.2vw;
}

.contact-info-email {
  font-weight: 300;
  margin-left: 2vw;
}

.contact-careers-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact-careers {
  width: 100vw;
}

.contact-careers-apply-today-button {
  filter: opacity(94%);
  font-size: 10vw;
  font-weight: 600;
  position: absolute;
}

.contact-careers-apply-today-button:hover {
  filter: opacity(97%);
  font-size: 10.2vw;
  font-weight: 700;
  position: absolute;
}

.contact-careers-apply-today-click {
  color: #eee4caaf;
  text-decoration: none;
}
.contact-careers-apply-today-click:hover {
  color: #e2746abc;
}
