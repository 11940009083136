.NavBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 5;
  background-image: url("../../assets/images/leather-accent.png");
  background-size: cover;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.9) inset;
  border: #01a7e3bc dotted 0.3vw;

  &__logo {
    width: 55vw;
    margin-top: 7vw;
  }
  &__logo:hover {
    filter: brightness(1.2) hue-rotate(120deg) grayscale(0.47);
  }

  &__buttons {
    width: 80vw;
    text-align: center;
    background-image: url("../../assets/images/leather.png");
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.9) inset;
    border: #01a7e3bc dotted 0.3vw;
    border-radius: 6.9vw;
    filter: drop-shadow(0px 10px 4px #000000);
    margin-top: 5vw;
    margin-bottom: -5vw;
  }
  &__services {
    height: 15vw;
    margin: 0 2vw;
  }
  &__services:hover {
    filter: brightness(1.2) hue-rotate(120deg) grayscale(0.47);
  }

  &__contact {
    height: 15vw;
    margin: 0 2vw;
  }
  &__contact:hover {
    filter: brightness(1.2) hue-rotate(120deg) grayscale(0.47);
  }
}
