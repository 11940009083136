.services-main {
  text-align: center;
  color: #eee4cc;
  margin-top: 2vw;
  height: 260vw;
}
.services-proline-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.services-proline {
  width: 80vw;
  margin: 3vw 0;
}
.services-proline-info {
  width: 80vw;
  font-size: 4vw;
  font-weight: 400;
}

.services-modelbthree-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.services-modelbthree {
  width: 80vw;
}
.services-modelbthree-info {
  width: 45vw;
  font-size: 4vw;
  font-weight: 400;
}
